<template>
  <div>
    <v-select
      outlined
      v-model="region"
      :items="regions"
      clearable
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Région"
    ></v-select>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "RegionFilter";

export default {
  name: COMPONENT_NAME,
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    // =>  METHODS

    // =>  COMPUTED
    const region = computed({
      get: () => store.getters["marmiton/getRegion"],
      set(e) {
        store.dispatch("marmiton/updateRegion", e);
      },
    });

    // =>  WATCH
    watch(region, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      await synchronizeFilterWithQueryString({
        dispatcher: "marmiton/updateRegion",
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: region.value,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      region,
      regions: [
        {
          id: "Auvergne-Rhone-Alpes",
          name: "Auvergne-Rhone-Alpes",
        },
        {
          id: "Bourgogne-Franche-Comte",
          name: "Bourgogne-Franche-Comte",
        },
        {
          id: "Brittany",
          name: "Bretagne",
        },
        {
          id: "Centre-Val de Loire",
          name: "Centre-Val de Loire",
        },
        {
          id: "Corsica",
          name: "Corse",
        },
        {
          id: "Grand Est",
          name: "Grand Est",
        },
        {
          id: "Hauts-de-France",
          name: "Hauts-de-France",
        },
        {
          id: "Ile-de-France",
          name: "Ile-de-France",
        },
        {
          id: "Normandy",
          name: "Normandie",
        },
        {
          id: "Nouvelle-Aquitaine",
          name: "Nouvelle-Aquitaine",
        },
        {
          id: "Occitanie",
          name: "Occitanie",
        },
        {
          id: "Pays de la Loire",
          name: "Pays de la Loire",
        },
        {
          id: "Provence-Alpes-Cote d'Azur",
          name: "Provence-Alpes-Cote d'Azur",
        },
        // {
        //   name: "Autres",
        // },
      ],
    };
  },
};
</script>

<style></style>
