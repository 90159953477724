<template>
  <v-list-item link @click.native="$emit('exportCsv')" :disabled="disabled">
    <v-list-item-title>Export CSV</v-list-item-title>
  </v-list-item>
</template>

<script>
import { watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";

import { saveRmraCustomEvent } from "@/utils/rmr-analytics";

export default {
  name: "ExportCsvButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    csvData: {
      type: String,
      default: "",
    },
    filename: {
      type: String,
      default: "exportCSV.csv",
    },
  },
  setup(props, { emit }) {
    const { route } = useProxy();

    // =>  METHODS
    const exportCSV = () => {
      if (props.disabled || props.csvData.length === 0) {
        return;
      }

      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", props.csvData]);
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = props.filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    // =>  COMPUTED

    // =>  WATCH
    watch(
      () => props.csvData,
      () => {
        exportCSV();
        saveRmraCustomEvent(route, "export");
      }
    );

    // =>  HOOKS

    // =>  DATA
    return {};
  },
};
</script>
