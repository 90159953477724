<template>
  <v-tooltip right v-if="dagList.length > 0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon small>
        <v-icon v-bind="attrs" v-on="on" class="tooltip-info">
          {{ icons.mdiInformationVariantCircle }}
        </v-icon>
      </v-btn>
    </template>
    <div>
      <strong>Basé sur les résultats des jobs suivants :</strong>
      <ul>
        <li
          v-for="({ name: dag_name, dag }, dag_index) in dagList"
          :key="`dag_info_${dag_index}`"
        >
          <span v-if="dag === undefined"
            >Aucune données pour le DAG "{{ dag_name }}"</span
          >
          <span v-else>
            "{{ dag_name }}"
            <span v-if="dag.is_active === false">
              (
              <v-icon color="warning">{{ icons.mdiAlert }}</v-icon>
              désactivé)
            </span>
            : {{ dag.timetable_description }} (dernière execution le
            {{ dag.run.execution_date }} => {{ dag.run.state }}
            <v-icon color="success" v-if="dag.run.state === 'success'">{{
              icons.mdiCheck
            }}</v-icon>
            <v-icon color="warning" v-else-if="dag.run.state === 'running'">{{
              icons.mdiInformationVariantCircle
            }}</v-icon>
            <v-icon color="error" v-else>{{ icons.mdiAlertCircle }}</v-icon>
            )
          </span>
        </li>
      </ul>
    </div>
  </v-tooltip>
</template>

<script>
import {
  mdiAlert,
  mdiAlertCircle,
  mdiCheck,
  mdiInformationVariantCircle,
} from "@mdi/js";
import { computed } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";

import { noDataText } from "@/utils/constants";

export default {
  name: "DagInfo",
  components: {},
  props: {
    dags: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    // =>  METHODS

    // =>  COMPUTED
    const dagList = computed(() => {
      const list = store.getters["common/getAirflowDagList"];

      return props.dags.map((dag_id) => ({
        name: dag_id,
        dag: list.find((item) => item.dag_id === dag_id),
      }));
    });

    // =>  WATCH

    // =>  HOOKS

    // =>  DATA
    return {
      noDataText,
      icons: {
        mdiAlert,
        mdiAlertCircle,
        mdiCheck,
        mdiInformationVariantCircle,
      },
      dagList,
    };
  },
};
</script>

<style scoped>
.tooltip-info {
  margin-left: 15px;
}
</style>
