<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        small
        v-bind="attrs"
        v-on="on"
        :class="`me-n3 mt-n2 ${position}`"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <export-csv-button
        v-if="csvButton"
        :disabled="isLoading || csvButton.disabled"
        :csvData="csvButton.csvData"
        :filename="csvButton.filename"
        @exportCsv="$emit('exportCsv')"
      />
      <export-excel-button
        v-if="excelButton"
        :disabled="isLoading || excelButton.disabled"
        :excelData="excelButton.excelData"
        :filename="excelButton.filename"
        @exportExcel="$emit('exportExcel')"
      />
      <export-pdf-button
        v-if="pdfButton"
        :disabled="isLoading || pdfButton.disabled"
        :getContainerRef="pdfButton.getContainerRef"
        :filename="pdfButton.filename"
      />
      <slot name="extraButtons"></slot>

      <v-list-item class="text-center" disabled v-if="isLoading">
        <v-list-item-title>
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiDotsVertical } from "@mdi/js";
import ExportCsvButton from "./buttons/ExportCsvButton.vue";
import ExportExcelButton from "./buttons/ExportExcelButton.vue";
import ExportPdfButton from "./buttons/ExportPdfButton.vue";

export default {
  name: "DotMenu",
  components: {
    ExportCsvButton,
    ExportExcelButton,
    ExportPdfButton,
  },
  props: {
    position: {
      type: String,
      validator(value) {
        return [
          "page-top-right",
          "page-top-left",
          "page-bottom-right",
          "page-bottom-left",
        ].includes(value);
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    csvButton: {
      type: Object,
      default: () => {
        return null;
      },
      validator(value) {
        const { csvData, filename, disabled } = value;
        if (csvData === undefined || typeof csvData !== "string") {
          return false;
        }
        if (
          filename !== undefined &&
          (typeof filename !== "string" || filename.length <= 0)
        ) {
          return false;
        }
        if (disabled !== undefined && typeof disabled !== "boolean") {
          return false;
        }

        return true;
      },
    },
    excelButton: {
      type: Object,
      default: () => {
        return null;
      },
      validator(value) {
        const { excelData, filename, disabled } = value;
        if (
          excelData === undefined ||
          typeof excelData !== "object" ||
          !(excelData instanceof Blob)
        ) {
          return false;
        }
        if (
          filename !== undefined &&
          (typeof filename !== "string" || filename.length <= 0)
        ) {
          return false;
        }
        if (disabled !== undefined && typeof disabled !== "boolean") {
          return false;
        }

        return true;
      },
    },
    pdfButton: {
      type: Object,
      default: () => {
        return null;
      },
      validator(value) {
        const { getContainerRef, filename, disabled } = value;
        if (
          getContainerRef === undefined ||
          typeof getContainerRef !== "function"
        ) {
          return false;
        }
        if (
          filename !== undefined &&
          (typeof filename !== "string" || filename.length <= 0)
        ) {
          return false;
        }
        if (disabled !== undefined && typeof disabled !== "boolean") {
          return false;
        }

        return true;
      },
    },
  },
  setup(props, { emit }) {
    /**************
     *  METHODS   *
     **************/

    // =>  COMPUTED

    // =>  WATCH

    // =>  HOOKS

    // =>  DATA
    return {
      icons: {
        mdiDotsVertical,
      },
    };
  },
};
</script>

<style scoped>
.page-top-right {
  position: absolute;
  top: 15px;
  right: 15px;
}
.page-top-left {
  position: absolute;
  top: 15px;
  left: 15px;
}
.page-bottom-right {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.page-bottom-left {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
</style>
