<template>
  <v-card :outlined="outlined" :class="classNames">
    <v-card-title v-if="title">
      {{ title }}
      <dag-info :dags="dags" />
    </v-card-title>
    <v-card-subtitle v-if="subTitle">{{ subTitle }}</v-card-subtitle>
    <v-card-text>
      <loader v-if="loading" />
      <v-container v-else-if="labels.length === 0" class="text-center">
        {{ noDataText }}
      </v-container>
      <vue-apex-charts
        v-show="!loading && labels.length > 0"
        type="bar"
        :height="height"
        :options="state.chartOptions"
        :series="series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { reactive, set, watch } from "@vue/composition-api";

import DagInfo from "@/components/common/menus/DagInfo";
import Loader from "@/components/common/Loader";

import { chartColors, noDataText } from "@/utils/constants";

export default {
  name: "BarChart",
  components: {
    DagInfo,
    Loader,
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    classNames: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "355",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    series: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Boolean,
      default: true,
    },
    datalabels: {
      type: Boolean,
      default: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    formatter: {
      type: Function,
      default: (val) => val,
    },
    colors: {
      type: Array,
      default: () => chartColors,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    xAxis: {
      type: Object,
      default: () => ({
        categories: [],
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      }),
    },
    yAxis: {
      type: Array,
      default: () => [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
        },
      ],
    },
    dags: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      chartOptions: {
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: props.legend,
          position: "bottom",
          fontSize: "14px",
          fontFamily: '"Inter", sans-serif',
        },
        colors: props.colors,
        dataLabels: {
          enabled: props.datalabels,
          textAnchor: "middle",
          style: {
            colors: ["#373D3FFF"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
          },
          offsetY: -30,
          formatter: props.formatter,
          dropShadow: {
            enabled: true,
          },
        },
        ...(props.xAxis ? { xaxis: props.xAxis } : {}),
        /* ...(props.xAxis
          ? {
              xaxis: {
                ...props.xAxis,
                categories:
                  "categories" in props.xAxis && props.xAxis.categories.length > 0
                    ? props.xAxis.categories
                    : props.labels,
              },
            }
          : { categories: props.labels }), */
        ...(props.yAxis ? { yaxis: props.yAxis } : {}),
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: props.formatter,
          },
        },
        fill: {
          opacity: 1,
        },
      },
    });

    // =>  METHODS
    const updateOptions = () => {
      set(state, "chartOptions", {
        ...state.chartOptions,
        series: props.series,
        labels: props.labels,
      });
    };

    // =>  COMPUTED

    // =>  WATCH
    watch(
      () => props.series,
      () => {
        updateOptions();
      }
    );
    watch(
      () => props.labels,
      () => {
        updateOptions();
      }
    );

    // =>  HOOKS

    // =>  DATA
    return {
      state,
      noDataText,
    };
  },
};
</script>
