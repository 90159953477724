<template>
  <div>
    <v-select
      outlined
      v-model="flavour"
      :items="flavours"
      clearable
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Saveur"
    ></v-select>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "FlavourFilter";

export default {
  name: COMPONENT_NAME,
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    // =>  METHODS

    // =>  COMPUTED
    const flavour = computed({
      get: () => store.getters["marmiton/getFlavour"],
      set(e) {
        store.dispatch("marmiton/updateFlavour", e);
      },
    });

    // =>  WATCH
    watch(flavour, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: newValue,
        // is_multiple: false,
        is_integer: true,
        // is_boolean: false,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      await synchronizeFilterWithQueryString({
        dispatcher: "marmiton/updateFlavour",
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: flavour.value,
        // is_multiple: false,
        is_integer: true,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      flavour,
      flavours: [
        {
          id: 1,
          name: "Sucré",
        },
        {
          id: 2,
          name: "Salé",
        },
      ],
    };
  },
};
</script>

<style></style>
