<template>
  <div>
    <v-select
      outlined
      v-model="rating"
      :items="state.ratings"
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Note"
    ></v-select>
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  reactive,
  set,
  watch,
} from "@vue/composition-api";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "RatingFilter";

export default {
  name: COMPONENT_NAME,
  setup(props, { emit }) {
    const { store } = useProxy();
    const { axiosGet } = useAxios();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const state = reactive({
      ratings: [],
    });

    // =>  METHODS

    // =>  COMPUTED
    const rating = computed({
      get: () => store.getters["marmiton/getRating"],
      set(e) {
        store.dispatch("marmiton/updateRating", e);
      },
    });

    // =>  WATCH
    watch(rating, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: newValue,
        // is_multiple: false,
        is_integer: true,
        // is_boolean: false,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      const { data } = await axiosGet("/regie/marmiton/ratings");
      set(state, "ratings", data);

      await synchronizeFilterWithQueryString({
        dispatcher: "marmiton/updateRating",
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: rating.value,
        // is_multiple: false,
        is_integer: true,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      state,
      rating,
    };
  },
};
</script>

<style></style>
