<template>
  <div>
    <v-container class="text-center">
      <v-progress-circular color="primary" indeterminate />
    </v-container>
    <v-container class="text-center" v-if="showText">
      {{ state.text }}
    </v-container>
  </div>
</template>

<script>
import { del, onMounted, reactive, set, watch } from "@vue/composition-api";

import {
  DATA_LOADING_ALTERNATIVE_TEXTS,
  dataLoadingText,
} from "@/utils/constants";

export default {
  name: "Loader",
  props: {
    showText: {
      type: Boolean,
      default: true,
    },
    useAlternativeTexts: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      text: dataLoadingText,
      isAlternativeText: false,
      alternativeText: "",
      //timer: null,
    });

    // =>  METHODS
    const resetText = () => {
      state.text = dataLoadingText;
      state.isAlternativeText = false;
    };

    const updateText = () => {
      if (state.isAlternativeText) {
        resetText();

        return;
      }

      const index = state.alternativeText
        ? DATA_LOADING_ALTERNATIVE_TEXTS.indexOf(state.alternativeText)
        : -1;
      if (index >= 0 && index < DATA_LOADING_ALTERNATIVE_TEXTS.length - 1) {
        state.text = state.alternativeText =
          DATA_LOADING_ALTERNATIVE_TEXTS[index + 1];
        state.isAlternativeText = true;

        return;
      }

      state.text = state.alternativeText = DATA_LOADING_ALTERNATIVE_TEXTS[0];
      state.isAlternativeText = true;
    };

    // =>  COMPUTED

    // =>  WATCH
    watch(
      () => state.text,
      () => {
        if (!props.useAlternativeTexts || !props.showText) {
          return;
        }

        set(
          state,
          "timer",
          setTimeout(() => {
            updateText();
          }, 3500)
        );
      }
    );

    // =>  HOOKS
    onMounted(() => {
      if (state?.timer) {
        // in case component is call with v-if
        clearTimeout(state.timer);
        del(state, "timer");
      }

      resetText(); // and start timer if needed, through watcher
    });

    // =>  DATA
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped></style>
