<template>
  <v-btn
    @click="onClick"
    :class="classList"
    :style="styles"
    :outlined="outlined"
    :disabled="disabled"
    :color="color"
  >
    <v-icon v-if="icon">{{ icon }}</v-icon>
    {{ title }}
  </v-btn>
</template>

<script>
import { onMounted } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";

import { saveRmraCustomEvent } from "@/utils/rmr-analytics";

export default {
  name: "ApplyFilters",
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Rechercher",
    },
    classList: {
      type: String,
      default: "pt-7 pb-6 mt-0 my-1",
    },
    styles: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    executeOnLoad: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { route, store } = useProxy();

    // =>  METHODS
    const setApplyFilters = () => {
      store.dispatch("common/updateApplyFilters", true);
    };

    const onClick = () => {
      setApplyFilters();
      saveRmraCustomEvent(route, "apply-filters");
    };

    // =>  COMPUTED

    // =>  WATCH

    // =>  HOOKS
    onMounted(() => {
      if (props.executeOnLoad) {
        // Be sure filters are initialized with dataFromUrl:
        setTimeout(() => {
          setApplyFilters();
        }, 500);
      }
    });

    // =>  DATA
    return {
      onClick,
    };
  },
};
</script>

<style></style>
