<template>
  <div>
    <v-text-field
      outlined
      dense
      label="Contenant"
      persistent-hint
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      v-model="textFilter"
    >
    </v-text-field>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "TextFilter";

export default {
  name: COMPONENT_NAME,
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    // =>  METHODS

    // =>  COMPUTED
    const textFilter = computed({
      get: () => store.getters[`${props.department}/getContainsText`],
      set(e) {
        store.dispatch(`${props.department}/updateContainsText`, e);
      },
    });

    // =>  WATCH
    watch(textFilter, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      await synchronizeFilterWithQueryString({
        dispatcher: `${props.department}/updateContainsText`,
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: textFilter.value,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      textFilter,
    };
  },
};
</script>

<style scoped></style>
