<template>
  <v-dialog v-model="download.running" width="70vw">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="(stepText, index) in stepTexts"
            :key="`computingStep_${index}`"
            :active="isDownloading || isComputing(index)"
            :disabled="!isDownloading && index > state.step"
          >
            <v-list-item-icon class="me-2">
              <v-icon size="22">
                {{ icons.computingSteps[index] }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ stepText
                }}{{
                  isComputing(index) ? "..." : state.step > index ? "." : ""
                }}
                <loader v-if="isComputing(index)" :showText="false" />
                <v-icon
                  color="success"
                  v-else-if="isDownloading || index < state.step"
                  >{{ icons.mdiCheck }}</v-icon
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            key="downloading"
            :active="isDownloading"
            :disabled="!isDownloading"
          >
            <v-list-item-icon class="me-2">
              <v-icon size="22">
                {{ icons.mdiFileDownloadOutline }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Transfert des données...
                <v-progress-linear
                  v-model="download.percentCompleted"
                  :height="height"
                  v-if="isDownloading"
                >
                  <template v-slot:default="{ value }">
                    <strong class="textWhite">{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiCalculatorVariantOutline,
  mdiCheck,
  mdiDatabaseArrowLeftOutline,
  mdiFileDownloadOutline,
  mdiFileImportOutline,
  mdiMerge,
  mdiPaletteSwatchVariant,
  mdiSendClockOutline,
} from "@mdi/js";
import {
  computed,
  del,
  onMounted,
  reactive,
  set,
  watch,
} from "@vue/composition-api";

import Loader from "@/components/common/Loader";

export const download_initial_state = () => ({
  running: false,
  percentCompleted: 0,
});

const STEP_DURATION = 5000;
const ACCELERATED_STEP_DURATION = 100;

export default {
  name: "ProgressBar",
  components: {
    Loader,
  },
  props: {
    title: {
      type: String,
      default: "Téléchargement...",
    },
    height: {
      type: Number,
      default: 25,
    },
    download: {
      type: Object,
      required: true,
      validator(value) {
        return (
          "running" in value &&
          "percentCompleted" in value &&
          typeof value.running === "boolean" &&
          !isNaN(parseFloat(value.percentCompleted))
        );
      },
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      step: 0,
      // timer: null,
      stepDuration: STEP_DURATION,
    });

    const stepTexts = [
      "Récupération des infos en base de données",
      "Exécution des calculs",
      "Agrégation des résultats",
      "Génération du fichier",
      "Application des styles",
      "Finalisation de l'envoi",
    ];

    // =>  METHODS
    const resetComponent = () => {
      del(state, "timer");
      state.step = 0;
      state.stepDuration = isDownloading.value
        ? ACCELERATED_STEP_DURATION
        : STEP_DURATION;
    };

    const scheduleNextStep = () => {
      set(
        state,
        "timer",
        setTimeout(() => {
          runNextStep();
        }, state.stepDuration)
      );
    };

    const runNextStep = () => {
      if (state.step >= stepTexts.length - 1) {
        return;
      }

      state.step += 1;
      scheduleNextStep();
    };

    const isComputing = (step) => {
      return !isDownloading.value && step === state.step;
    };

    // =>  COMPUTED
    const isDownloading = computed(() => props.download.percentCompleted > 0);

    // =>  WATCH
    watch(
      () => props.download.running,
      (newVal) => {
        if (newVal !== true) {
          return;
        }

        resetComponent();
        scheduleNextStep();
      }
    );

    watch(
      () => isDownloading.value,
      (newVal) => {
        if (newVal !== true) {
          return;
        }

        del(state, "timer");
        state.stepDuration = ACCELERATED_STEP_DURATION;
        runNextStep();
      }
    );

    // =>  HOOKS
    onMounted(() => {
      resetComponent();
    });

    // =>  DATA
    return {
      state,
      stepTexts,
      isDownloading,
      isComputing,
      icons: {
        computingSteps: [
          mdiDatabaseArrowLeftOutline,
          mdiCalculatorVariantOutline,
          mdiMerge,
          mdiFileImportOutline,
          mdiPaletteSwatchVariant,
          mdiSendClockOutline,
        ],
        mdiCheck,
        mdiFileDownloadOutline,
      },
      dialogModel: {
        dialog: props.download.running,
      },
    };
  },
};
</script>

<style scoped>
.textWhite {
  color: #fff;
}
</style>
