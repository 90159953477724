<template>
  <div>
    <v-autocomplete
      v-model="storedIngredients"
      :items="state.items"
      item-text="name"
      item-value="name"
      :search-input.sync="state.search"
      v-on:keyup="debouncedGetData"
      multiple
      clearable
      dense
      hide-details
      outlined
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      :label="label"
    ></v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import {
  computed,
  onMounted,
  reactive,
  set,
  watch,
} from "@vue/composition-api";

import { ingredientGroupAutoCompleteValue } from "@/components/regie/common/utils/ingredientGroup.js";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { runParallelAsyncs } from "@/utils/async";
import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "IngredientFilter";

export default {
  name: COMPONENT_NAME,
  props: {
    label: {
      type: String,
      default: "Ingrédients",
    },
    withGroups: {
      type: Boolean,
      default: true,
    },
    store: {
      type: String,
      default: "marmiton",
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["marmiton"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getMarmitonIngredientsToInclude",
    },
    storeUpdater: {
      type: String,
      default: "updateMarmitonIngredientsToInclude",
    },
    toExclude: {
      type: Boolean,
      default: false,
    },
    anchor: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();
    const { axiosGet } = useAxios();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const state = reactive({
      search: "",
      items: [],
    });

    const queryStringParam = props.toExclude
      ? URL_PARAM_NAMES["IngredientToExcludeFilter"]
      : URL_PARAM_NAMES[COMPONENT_NAME];

    // =>  METHODS
    const debouncedGetData = debounce(async () => {
      await getData();
    }, 500);

    const getData = async () => {
      const {
        ingredientGroupsResponse: {
          data: { items: ingredientGroupsData },
        },
        ingredientsResponse: { data: ingredientsData },
      } = await runParallelAsyncs({
        ingredientGroupsResponse: axiosGet(
          "/regie/marmiton/ingredient-groups",
          {
            params: { search_text: state.search },
          }
        ),
        ingredientsResponse: axiosGet("/regie/marmiton/ingredients", {
          params: { search_text: state.search },
        }),
      });

      set(
        state,
        "items",
        storedIngredients.value
          .concat(
            props.withGroups
              ? ingredientGroupsData.map((ingredientGroup) => ({
                  name: ingredientGroupAutoCompleteValue(ingredientGroup),
                }))
              : []
          )
          .concat(ingredientsData)
      );
    };

    // =>  COMPUTED
    const storedIngredients = computed({
      get: () => store.getters[`${props.store}/${props.storeGetter}`],
      set(e) {
        store.dispatch(`${props.store}/${props.storeUpdater}`, e);
      },
    });

    // =>  WATCH
    watch(storedIngredients, (newValue) => {
      addQueryStringParam(
        {
          param: queryStringParam,
          value: newValue,
          is_multiple: true,
          // is_integer: false,
          // is_boolean: false,
        },
        props.anchor
      );
    });

    // =>  HOOKS
    onMounted(async () => {
      await getData();

      await synchronizeFilterWithQueryString({
        dispatcher: `${props.store}/${props.storeUpdater}`,
        param: queryStringParam,
        value: storedIngredients.value,
        is_multiple: true,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      state,
      debouncedGetData,
      storedIngredients,
    };
  },
};
</script>

<style></style>
