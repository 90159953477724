<template>
  <div>
    <vue-apex-charts
      :type="type"
      :height="state.options.chart.height"
      :options="state.options"
      :color="color"
      :xaxisType="xaxisType"
      :series="series"
      v-if="series.length > 0"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { onMounted, reactive, set, watch } from "@vue/composition-api";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    type: {
      type: String,
      default: "bar",
    },
    legend: {
      type: Boolean,
      default: true,
    },
    series: {
      type: Array,
      default: [],
    },
    color: {
      type: Array,
      default: () => ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
    },
    xaxisType: {
      type: String,
      default: "text",
    },
    chartOptions: {
      type: Object,
      default: {},
    },
    chartCategories: {
      type: Array,
      default: [],
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      options: {
        chart: {
          height: "500px",
        },
      },
    });

    // =>  METHODS
    const updateOptions = () => {
      set(state, "options", {
        ...props.chartOptions,
        chart: {
          ...(props.chartOptions?.chart ?? {}),
          height: props.chartOptions?.chart?.height ?? "500px",
        },
        legend: {
          show: props.legend,
        },
        colors: props.color,
        xaxis: {
          ...(props.chartOptions?.xaxis ?? {}),
          categories: props.chartCategories,
          type: props.xaxisType,
          ...(props.chartOptions?.xaxis?.labels ?? {
            labels: {
              rotate: 0,
              hideOverlappingLabels: true,
              trim: true,
            },
          }),
        },
      });
    };

    // =>  COMPUTED

    // =>  WATCH
    watch(
      () => props.chartCategories,
      () => {
        updateOptions();
      }
    );

    watch(
      () => props.chartOptions,
      () => {
        updateOptions();
      }
    );

    watch(
      () => props.color,
      () => {
        updateOptions();
      }
    );

    watch(
      () => props.legend,
      () => {
        updateOptions();
      }
    );

    // =>  HOOKS
    onMounted(() => {
      updateOptions();
    });

    // =>  DATA
    return {
      state,
    };
  },
};
</script>
