<template>
  <v-list-item link @click.native="$emit('exportExcel')" :disabled="disabled">
    <v-list-item-title>Export Excel</v-list-item-title>
  </v-list-item>
</template>

<script>
import { watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";

import { saveRmraCustomEvent } from "@/utils/rmr-analytics";

export default {
  name: "ExportExcelButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    excelData: {
      type: Blob,
      default: () => new Blob([""], { type: "text/plain" }),
    },
    filename: {
      type: String,
      default: "export.xlsx",
    },
  },
  setup(props, { emit }) {
    const { route } = useProxy();

    // =>  METHODS
    const exportExcel = () => {
      if (props.disabled || props.excelData.length === 0) {
        return;
      }

      const downloadLink = document.createElement("a");
      const blob = new Blob([props.excelData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = props.filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    // =>  COMPUTED

    // =>  WATCH
    watch(
      () => props.excelData,
      () => {
        exportExcel();
        saveRmraCustomEvent(route, "export");
      }
    );

    // =>  HOOKS

    // =>  DATA
    return {};
  },
};
</script>
