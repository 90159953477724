<template>
  <v-autocomplete
    outlined
    v-model="selected"
    :items="siteList"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les sites"
    multiple
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
    :dense="dense"
  ></v-autocomplete>
</template>

<script>
import isEqual from "lodash/isEqual";

import {
  computed,
  onMounted,
  reactive,
  set,
  watch,
} from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "SiteFilter";

/**
 * Either call this component giving an `initialValue` (int[])
 *  and listening on `valueChanged` event,
 * Or call it specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
export default {
  name: COMPONENT_NAME,
  props: {
    initialValue: {
      type: Array,
      validator(values) {
        return (
          values.length === 0 ||
          values.every((value) => typeof value === "number")
        );
      },
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return [
          "audience",
          "c2c",
          "finance",
          "gd",
          "general",
          "linkMessApps",
          "premium",
          "regie",
          "webPerf",
        ].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getSites",
    },
    storeUpdater: {
      type: String,
      default: "updateSites",
    },
    dependsOnSiteGroups: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const page = reactive({
      isInitialized: false,
      initialUrlValue: [], // in case we need to load from url params
    });

    // =>  METHODS

    // =>  COMPUTED
    const siteList = computed(() => store.getters["common/getSiteList"]);
    const viaStore = computed(() => props.store !== undefined);

    const selected = computed({
      get() {
        if (viaStore.value) {
          return store.getters[`${props.store}/${props.storeGetter}`];
        }

        return page.initialUrlValue ?? props.initialValue;
      },
      set(e) {
        if (viaStore.value === false) {
          if (!isEqual(e, page.initialUrlValue ?? props.initialValue)) {
            emit("valueChanged", e);
          }

          return;
        }

        store.dispatch(`${props.store}/${props.storeUpdater}`, e);
      },
    });

    // =>  WATCH
    watch(selected, (newValue) => {
      if (page.isInitialized) {
        // Only update user changes, not initialization
        addQueryStringParam({
          param: URL_PARAM_NAMES[COMPONENT_NAME],
          value: newValue,
          is_multiple: true,
          is_integer: true,
          // is_boolean: false,
        });
      }
    });

    // =>  HOOKS
    onMounted(async () => {
      await store.dispatch("common/getSiteList");

      set(
        page,
        "initialUrlValue",
        await synchronizeFilterWithQueryString({
          dispatcher: viaStore.value
            ? `${props.store}/${props.storeUpdater}`
            : "",
          param: URL_PARAM_NAMES[COMPONENT_NAME],
          value: selected.value,
          is_multiple: true,
          is_integer: true,
          // is_boolean: false,
          dependsOn: props.dependsOnSiteGroups
            ? URL_PARAM_NAMES["SiteGroupFilter"]
            : undefined,
        })
      );

      page.isInitialized = true;
    });

    // =>  DATA
    return {
      siteList,
      selected,
    };
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
