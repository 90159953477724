<template>
  <div ref="pdfContainer" class="pdfContainer">
    <component v-if="logo" :is="logo"></component>
    <p class="text-2xl mb-6">Infos visiteurs</p>
    <v-card class="mb-6 pa-2" outlined>
      <filters :department="department"></filters>
    </v-card>

    <v-row>
      <v-col cols="12">
        <top-stats-card :department="department" />
      </v-col>
    </v-row>

    <v-row v-if="['regie'].includes(department)">
      <v-col cols="12">
        <repartition-by-sites-chart :department="department" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <top-articles :department="department" />
      </v-col>
    </v-row>

    <v-row v-if="['marmiton'].includes(department)">
      <v-col cols="12">
        <top-ingredients-table
          class="mb-6"
          :startDate="startDate"
          :endDate="endDate"
          :department="department"
          outlined
        ></top-ingredients-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <repartition-by-ages-chart :department="department" />
      </v-col>
      <v-col cols="6">
        <!-- repartition-by-countries-chart / -->
        <repartition-by-regions-chart :department="department" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <repartition-by-days-chart :department="department" />
      </v-col>
      <v-col cols="6">
        <repartition-by-hours-chart :department="department" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <views-by-day
          class="mb-6"
          title="Vues / Jour"
          :department="department"
        />
      </v-col>
    </v-row>

    <dot-menu
      position="page-top-right"
      :isLoading="isLoading"
      :pdfButton="{
        getContainerRef: () => $refs.pdfContainer,
        filename: 'regie_info_utilisateurs.pdf',
        disabled: disabled,
      }"
      @exportExcel="exportExcel"
      :excelButton="{
        excelData,
        filename: `regie_info_utilisateurs.xlsx`,
        disabled: disabled,
      }"
    />
    <progress-bar :download="download" />
  </div>
</template>

<script>
import axios from "@axios";

import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";
import Filters from "@/components/regie/visitors-info/filters/Filters.vue";
import RepartitionByAgesChart from "@/components/regie/visitors-info/cards/RepartitionByAgesChart";
// import RepartitionByCountriesChart from "@/components/regie/visitors-info/cards/RepartitionByCountriesChart";
import RepartitionByRegionsChart from "@/components/regie/visitors-info/cards/RepartitionByRegionsChart";
import RepartitionByDaysChart from "@/components/regie/visitors-info/cards/RepartitionByDaysChart";
import RepartitionByHoursChart from "@/components/regie/visitors-info/cards/RepartitionByHoursChart";
import RepartitionBySitesChart from "@/components/regie/visitors-info/cards/RepartitionBySitesChart";
import TopArticles from "@/components/regie/visitors-info/tables/TopArticles";
import TopIngredientsTable from "@/components/regie/marmiton/tables/TopIngredients";
import TopStatsCard from "@/components/regie/visitors-info/cards/TopStatsCard";
import ViewsByDay from "@/components/regie/visitors-info/cards/ViewsByDay.vue";

import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";
import useAxios from "@/hooks/useAxios";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "VisitorsInfo",
  components: {
    DotMenu,
    Filters,
    ProgressBar,
    RepartitionByAgesChart,
    // RepartitionByCountriesChart,
    RepartitionByDaysChart,
    RepartitionByHoursChart,
    RepartitionByRegionsChart,
    RepartitionBySitesChart,
    TopArticles,
    TopIngredientsTable,
    TopStatsCard,
    ViewsByDay,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  async created() {
    await this.setSiteList();
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
    logo: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      excelData: new Blob([""], { type: "text/plain" }),
      download: download_initial_state(),
    };
  },
  setup() {
    const { isLoading, axiosGet } = useAxios();

    return {
      axiosGet,
      isLoading,
    };
  },
  methods: {
    async setSiteList() {
      if (this.department === "marmiton") {
        const {
          data: { id: site_id },
        } = await axios.get("/sites/by-name/", {
          params: { name: "marmiton" },
        });
        this.$store.dispatch("marmiton/updateSites", site_id ? [site_id] : []);
      }
    },
    async exportExcel() {
      if (this.disabled) {
        alert("Sélectionnez une période et un site !");

        return false;
      }

      this.download.running = true;
      const { data } = await this.axiosGet(
        "/regie/visitor-info/export",
        {
          start_date: this.startDate,
          end_date: this.endDate,
          site_ids: this.sites.join(","),
          site_group_ids: this.siteGroups.join(","),
          keywords: this.searchText,
          ...(this.useMarmitonFilters
            ? {
                forMarmiton: true,
                ...(this.ingredientsToInclude
                  ? { ingredients: this.ingredientsToInclude }
                  : {}),
                ...(this.flavour ? { flavour: this.flavour } : {}),
                ...(this.region ? { region: this.region } : {}),
                ...(this.ageSlot ? { ageSlot: this.ageSlot } : {}),
              }
            : {}),
        },
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            this.download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      this.excelData = data;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    disabled() {
      return (
        !this.sites ||
        this.sites.length === 0 ||
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      );
    },
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    siteGroups() {
      return this.$store.getters[`${this.department}/getSiteGroups`];
    },
    startDate() {
      return this.$store.getters[`${this.department}/getDates`][0];
    },
    endDate() {
      return this.$store.getters[`${this.department}/getDates`][1];
    },
    searchText() {
      return this.$store.getters[`${this.department}/getContainsText`];
    },
    flavour() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getFlavour"]
        : "";
    },
    region() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRegion"]
        : "";
    },
    ageSlot() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getAgeSlot"]
        : "";
    },
    ingredientsToInclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonIngredientsToInclude"]
        : [];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
  },
  watch: {
    async department() {
      await this.setSiteList();
    },
    ["download.percentCompleted"]() {
      if (this.download.percentCompleted >= 100) {
        setTimeout(() => {
          this.download = download_initial_state();
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
.pdfContainer {
  position: relative;
}
</style>
