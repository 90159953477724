<template>
  <div>
    <v-select
      outlined
      v-model="ageSlot"
      :items="ageSlots"
      clearable
      item-text="name"
      item-value="name"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Tranche d'âge"
    ></v-select>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "AgeFilter";

export default {
  name: COMPONENT_NAME,
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    // =>  METHODS

    // =>  COMPUTED
    const ageSlot = computed({
      get: () => store.getters["marmiton/getAgeSlot"],
      set(e) {
        store.dispatch("marmiton/updateAgeSlot", e);
      },
    });

    // =>  WATCH
    watch(ageSlot, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: newValue,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      await synchronizeFilterWithQueryString({
        dispatcher: "marmiton/updateAgeSlot",
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: ageSlot.value,
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });
    });

    // =>  DATA
    return {
      ageSlot,
      ageSlots: [
        {
          name: "18-24",
        },
        {
          name: "25-34",
        },
        {
          name: "35-44",
        },
        {
          name: "45-54",
        },
        {
          name: "55-64",
        },
        {
          name: "65+",
        },
      ],
    };
  },
};
</script>

<style></style>
