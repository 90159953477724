<template>
  <div>
    <v-menu
      v-model="menu"
      max-width="290px"
      min-width="auto"
      style="top: 228px"
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          v-model="dateText"
          v-bind="attrs"
          :prepend-inner-icon="icons.mdiCalendar"
          v-on="on"
          :disabled="disabled"
        ></v-text-field>
      </template>
      <v-row>
        <v-col>
          <v-date-picker
            v-model="state.dates"
            :range="isRange"
            :type="format"
            v-bind="{
              ...(dateMin ? { min: dateMin } : {}),
              ...(dateMax ? { max: dateMax } : {}),
            }"
            locale="FR-fr"
            first-day-of-week="1"
            selected-items-text="Durée selectionnée"
            :disabled="disabled"
          ></v-date-picker>
        </v-col>
      </v-row>
    </v-menu>
  </div>
</template>

<script>
import isEqual from "lodash/isEqual";

import { mdiCalendar } from "@mdi/js";
import {
  computed,
  onMounted,
  reactive,
  set,
  watch,
} from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";
import { getDateStr, getMonthDateStr, ONE_DAY_MICROTIME } from "@/utils/dates";

const COMPONENT_NAME = "DateFilter";

const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getTime() - ONE_DAY_MICROTIME);
const TOMORROW = new Date(TODAY.getTime() + ONE_DAY_MICROTIME);

/**
 * Either call this component giving an `initialValue`
 *  and listening on `valueChanged` event,
 * Or call it specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 *
 * In case of `isRange` === true,
 *  `initialValue` (or storedValue) must be an array [startDate, endDate]
 * otherwise, it must be a string value.
 */
export default {
  name: COMPONENT_NAME,
  props: {
    periodType: {
      type: String,
      default: "",
      validator(value) {
        return ["past-and-today", "past-only", "future-only", ""].includes(
          value
        );
      },
    },
    initialValue: {
      type: String | Array,
      validator(value) {
        if (Array.isArray(value)) {
          return value.length === 2 && value[0] !== undefined;
        }
        return typeof value === "string";
      },
    },
    isRange: {
      type: Boolean,
      default: true,
    },
    range: {
      type: String | Number,
      default: "14",
      validator(value) {
        return parseInt(value, 10);
      },
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "date",
      validator(value) {
        return ["date", "month"].includes(value);
      },
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return [
          "audience",
          "c2c",
          "finance",
          "gd",
          "general",
          "linkMessApps",
          "marmiton",
          "premium",
          "regie",
          "rmra",
          "webPerf",
          "gtrends",
        ].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getDates",
    },
    storeUpdater: {
      type: String,
      default: "updateDates",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const state = reactive({
      dates: props.isRange ? [] : "",
    });

    // =>  METHODS
    const updateQueryString = () => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: state.dates,
        is_multiple: props.isRange,
        // is_integer: false,
        // is_boolean: false,
      });
    };

    const initDates = () => {
      if (props.isRange) {
        initRangeDates();
      } else {
        initStartDate();
      }
    };

    const initRangeDates = () => {
      if (
        storedDates?.value?.length !== 2 ||
        Object.values(storedDates.value).some((x) => x === undefined)
      ) {
        if (props.periodType === "past-and-today") {
          if (dateMax.value !== "") {
            const endDate = new Date(dateMax.value);
            const startDate = new Date(
              endDate.getTime() - props.range * ONE_DAY_MICROTIME
            );
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          } else {
            const endDate = TODAY;
            const startDate = new Date(
              TODAY.getTime() - props.range * ONE_DAY_MICROTIME
            );
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          }
        } else if (props.periodType === "past-only") {
          if (dateMax.value !== "") {
            const endDate = new Date(dateMax.value);
            const startDate = new Date(
              endDate.getTime() - props.range * ONE_DAY_MICROTIME
            );
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          } else {
            const endDate = YESTERDAY;
            const startDate = new Date(
              YESTERDAY.getTime() - props.range * ONE_DAY_MICROTIME
            );
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          }
        } else if (props.periodType === "future-only") {
          if (dateMin.value !== "") {
            const startDate = new Date(dateMin.value);
            const endDate = new Date(
              startDate.getTime() + props.range * ONE_DAY_MICROTIME
            );
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          } else {
            const startDate = TOMORROW;
            const endDate = new Date(
              TOMORROW.getTime() + props.range * ONE_DAY_MICROTIME
            );
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          }
        } else {
          if (dateMin.value !== "" && dateMax.value !== "") {
            const startDate = new Date(dateMin.value);
            const endDate = new Date(dateMax.value);
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          } else if (dateMin.value !== "") {
            const startDate = new Date(dateMin.value);
            const endDate = new Date(
              startDate.getTime() + props.range * ONE_DAY_MICROTIME
            );
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          } else if (dateMax.value !== "") {
            const endDate = new Date(dateMax.value);
            const startDate = new Date(
              endDate.getTime() - props.range * ONE_DAY_MICROTIME
            );
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          } else {
            const endDate = TODAY;
            const startDate = new Date(
              TODAY.getTime() - props.range * ONE_DAY_MICROTIME
            );
            set(state, "dates", [
              getDateString(startDate),
              getDateString(endDate),
            ]);
          }
        }
      } else if (!isEqual(state.dates, storedDates.value)) {
        set(state, "dates", storedDates.value);
      }
    };

    const initStartDate = () => {
      if (storedDates?.value === undefined || storedDates.value.length < 7) {
        if (props.periodType === "past-only") {
          set(state, "dates", dateMax.value);
        } else if (props.periodType === "future-only") {
          set(state, "dates", dateMin.value);
        } else {
          set(state, "dates", getDateString(TODAY));
        }
      } else if (!isEqual(state.dates, storedDates.value)) {
        set(state, "dates", storedDates.value);
      }
    };

    const getDateString = (_date) => {
      if (props.format === "month") {
        return getMonthDateStr(_date);
      }

      return getDateStr(_date);
    };

    // =>  COMPUTED
    const viaStore = computed(() => props.store !== undefined);

    const storedDates = computed(() => {
      return viaStore.value === true
        ? store.getters[`${props.store}/${props.storeGetter}`]
        : props.initialValue;
    });

    const dateText = computed(() => {
      const _dates = storedDates.value;

      return props.isRange ? _dates.join(" => ") : _dates;
    });

    const dateMin = computed(() => {
      if (props.min !== "") {
        return props.min;
      }
      if (props.periodType === "future-only") {
        return getDateString(TOMORROW);
      }

      return "";
    });

    const dateMax = computed(() => {
      if (props.max !== "") {
        return props.max;
      }
      if (props.periodType === "past-only") {
        return getDateString(YESTERDAY);
      }

      return "";
    });

    // =>  WATCH
    watch(storedDates, (newValue) => {
      if (!props.isRange || newValue.length === 2) {
        initDates();
      }
    });

    watch(
      () => state.dates,
      (newValue) => {
        if (viaStore.value === false) {
          if (newValue !== props.initialValue) {
            updateQueryString();
            emit("valueChanged", newValue);
          }

          return;
        }

        if (props.isRange) {
          if (newValue.length === 2 && newValue[0] !== undefined) {
            store.dispatch(`${props.store}/${props.storeUpdater}`, newValue);
            updateQueryString();
          }
        } else if (newValue !== undefined) {
          store.dispatch(`${props.store}/${props.storeUpdater}`, newValue);
          updateQueryString();
        }
      }
    );

    // =>  HOOKS
    onMounted(async () => {
      const _dates = await synchronizeFilterWithQueryString({
        dispatcher: viaStore.value
          ? `${props.store}/${props.storeUpdater}`
          : "",
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: state.dates,
        is_multiple: props.isRange,
        // is_integer: false,
        // is_boolean: false,
        // dependsOn: undefined,
      });

      if (_dates && _dates.length > 0) {
        set(state, "dates", _dates);

        return;
      }

      initDates();
    });

    // =>  DATA
    return {
      dateMin,
      dateMax,
      state,
      dateText,
      icons: {
        mdiCalendar,
      },
      menu: false,
    };
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 215px !important;
  right: 0px !important;
}
</style>
