<template>
  <v-card outlined>
    <v-card-title>Répartition par jour</v-card-title>
    <v-card-text>
      <loader v-if="loading" />
      <v-container v-else-if="resultText.length === 0" class="text-center">
        {{ noDataText }}
      </v-container>
      <v-container v-else>
        {{ resultText }}
        <v-list outlined>
          <v-list-item v-for="item in resultDetails" :key="item.day">
            <v-list-item-content class="d-block">
              <v-list-item-title class="text-sm">
                {{ item.day }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-2xl font-weight-semibold">
                {{ item.percent.display }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";
import { noDataText } from "@/utils/constants";
import { daysFrArray } from "@/utils/dates";
import { formatPercent } from "@/utils/formatting";

import Loader from "@/components/common/Loader";

import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";

export default {
  name: "RepartitionByDaysChart",
  components: {
    Loader,
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
  },
  data() {
    return {
      loading: false,
      noDataText,
      resultText: "",
      resultDetails: [],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    resetData() {
      this.resultText = "";
      this.resultDetails = [];
    },
    formatResult: (data) => {
      const repartition = Object.entries(data).map(([dayIndex, percent]) => {
        return {
          day: daysFrArray[dayIndex],
          percent: {
            raw: Math.round(percent * 100),
            display: formatPercent(percent, 0),
          },
        };
      });
      const sortedRepartition = [...repartition].sort(
        (a, b) => b.percent.raw - a.percent.raw // desc
      );

      let nbDays = 0,
        percent = 0;
      for (const day of sortedRepartition) {
        nbDays++;
        percent += day.percent.raw;

        if (nbDays > 3) {
          return {
            text: "La fréquentation est plutôt équilibrée tout au long de la semaine : ",
            details: repartition,
          };
        } else if (percent > 50) {
          const details = sortedRepartition.slice(0, nbDays);
          if (nbDays > 1) {
            return {
              text: `La fréquentation se concentre principalement (${formatPercent(
                percent / 100,
                0
              )}) sur les jours suivants : `,
              details,
            };
          }

          return {
            text: `La fréquentation se concentre principalement (${formatPercent(
              percent / 100,
              0
            )}) sur le jour suivant : `,
            details,
          };
        }
      }
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.sites &&
        this.sites.length > 0 &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          sites_id: this.sites.join(","),
          keyword: this.searchText,
          ...(this.useMarmitonFilters
            ? {
                ...(this.ingredientsToInclude
                  ? { ingredients: this.ingredientsToInclude }
                  : {}),
                ...(this.flavour ? { flavour: this.flavour } : {}),
                ...(this.region ? { region: this.region } : {}),
                ...(this.ageSlot ? { ageSlot: this.ageSlot } : {}),
                ...(this.dish ? { dish: this.dish } : {}),
                ...(this.cost ? { cost: this.cost } : {}),
                ...(this.duration ? { duration: this.duration } : {}),
                ...(this.rating ? { rating: this.rating } : {}),
                ...(this.rates ? { rates: this.rates } : {}),
              }
            : {}),
        };
        this.loading = true;

        try {
          const { data } = await this.axiosGet(
            "/regie/visitor-info/by-days",
            params
          );

          const { text, details } = this.formatResult(data);
          this.resultText = text;
          this.resultDetails = details;

          this.loading = false;
        } catch (err) {
          this.loading = false;
        }

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters[`${this.department}/getSites`];
    },
    startDate() {
      return this.$store.getters[`${this.department}/getDates`][0];
    },
    endDate() {
      return this.$store.getters[`${this.department}/getDates`][1];
    },
    searchText() {
      return this.$store.getters[`${this.department}/getContainsText`];
    },
    flavour() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getFlavour"]
        : "";
    },
    region() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRegion"]
        : "";
    },
    ageSlot() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getAgeSlot"]
        : "";
    },
    ingredientsToInclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonIngredientsToInclude"]
        : [];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
    dish() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getDish"]
        : "";
    },
    cost() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getCost"]
        : "";
    },
    duration() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getDuration"]
        : 0;
    },
    rating() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRating"]
        : 0;
    },
    rates() {
      return this.useMarmitonFilters
        ? this.$store.getters["marmiton/getRates"]
        : 0;
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    department() {
      this.resetData();
    },
  },
};
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: none transparent;
}
</style>
